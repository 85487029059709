
.navbar {
  background-color: #FFBE00!important;
}

.navbar-brand {
  background-color: #FFBE00;
}

.details-contents {
  margin-top: 1em;
}

.is-primary {
  background-color: #FFBE00!important;
}

.button.is-multiline {
  min-height: 5.25em;
  white-space: unset;
  flex-direction: column;
}

.pointer {
  cursor: pointer;
}

.recentTitle {
  font-weight: bold;
  font-size: large;
  margin-bottom: 0.5em;
  margin-top: 2em;
}

.imageWrap {
  display: inline-block;
  position: relative;
  min-height: 300px;
}

.deleteButton {
  position: absolute!important;
  background-color: #FFBE00!important;
  top: 10px!important;
  right: 0px;
  cursor: pointer;
}

.uploadButton {
  position: absolute!important;
  background-color: #FFBE00!important;
  top: 10px!important;
  right: 0px;
  cursor: pointer;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.button.is-multiline {
  min-height: 1.5em;
  white-space: unset;
  height: auto;
  flex-direction: column;
}

.textBoxContainer {
  display: flex;
}

.fill-width {
  flex: 1;
}